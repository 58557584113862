import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Axios from 'axios';
import '../styles/other.css';
import '../styles/pages.css';
import '../styles/tiles.css';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

class AreaHeaderTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            kraObject: {id_kra: 0, title:"Area",description: "description",vision: "vision"},
        };
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = () => {
        const id_kra = localStorage.getItem("actualKRA") || 0;
        Axios.get("/api/kra/get/"+id_kra).then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            this.setState({ kraObject: response.data.result[0] });
        });
    }

    render(){
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return(
            <div className="universalTile clickable" onClick={() => this.setState({ redirectTo: "/Area" })}>
                <div className="verticalStack">
                    <div className="horizontalStack">
                        <span className="fontPoppinsBold25">{this.state.kraObject.title}</span>
                    </div>
                </div>
            </div>
        );
	}
}

export default AreaHeaderTile;

