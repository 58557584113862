import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import '../styles/tiles.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';
import ExpandButton from '../components/ExpandButton.js';
import AreaHeaderTile from '../tiles/AreaHeaderTile.js';

// assets
import icon_add_white from '../assets/icon_add_white.png';

class PlanEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            title: "",
            content: "",
            id_plan: 0,
            quarter_1: "",
            quarter_2: "",
            quarter_3: "",
            quarter_4: "",
            year_vision: "",
            year: 0,
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = () => {
        const id = localStorage.getItem("actualKRA") || undefined;
        if(id != undefined && Number.isInteger(Number(id))){
            Axios.get("/api/plan/get?kra="+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                if(response.data.result.length >= 1){
                    this.setState({ id_plan: response.data.result[0].id_plan });
                    this.setState({ quarter_1: response.data.result[0].quarter_1 });
                    this.setState({ quarter_2: response.data.result[0].quarter_2 });
                    this.setState({ quarter_3: response.data.result[0].quarter_3 });
                    this.setState({ quarter_4: response.data.result[0].quarter_4 });
                    this.setState({ year_vision: response.data.result[0].year_vision });
                    this.setState({ year: response.data.result[0].year });
                }
            });

        }

    }

    onSavePlan = () => {
        var id_plan = this.state.id_plan;
        var id_kra = localStorage.getItem("actualKRA") || 0;
        var quarter_1 = this.state.quarter_1;
        var quarter_2 = this.state.quarter_2;
        var quarter_3 = this.state.quarter_3;
        var quarter_4 = this.state.quarter_4;
        var year_vision = this.state.year_vision;
        var year = this.state.year;

        if(year < 2000){
            alert("Rok nesmí být prázdný a musí být větší než 2000.");
        }

        // check if exists plan with same year
        Axios.get("/api/plan/get?kra="+id_kra+"&year="+year).then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }

            var url = "/api/plan/ins";
            if(response.data.result.length >= 1){ url = "/api/plan/upd/"+id_plan; }

            if( (url == "/api/plan/ins") && (window.confirm("Jste si jisti, že chcete vytvořit plán pro nový rok? Po této akci půjde přistupovat k bývalému plánu pouze z databáze!") != true)){
                // nechci
                return;
            }

            Axios.post(url, {
                id_kra: id_kra,
                quarter_1: quarter_1,
                quarter_2: quarter_2,
                quarter_3: quarter_3,
                quarter_4: quarter_4,
                year_vision: year_vision,
                year: year
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                this.setState({ redirectTo: "/Area" });
            });

        });

    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 70px)", overflowY: "scroll"}}>

                <AreaHeaderTile />

                <div className="universalTile" style={{marginBottom: 20}}>
                    <div className="verticalStack">
                        <span className="fontPoppinsSemiBold15">{"Upravit plán na daný rok"}</span>
                        <span className="fontPoppinsRegular13Gray">{"Pokud chceš vytvořit plán na nový rok, prostě jen změň rok na nový a ulož to."}</span>
                        <div style={{height: 10}}></div>

                        <div className="verticalStack" style={{padding: 12, backgroundColor: "#F4F4F8"}}>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>{"Plán na "+this.state.year+" / 01:"}</span>
                                <textarea className="" type="text" style={{flex: 1, border: 0, resize: "none"}} rows={5} name="obsah" placeholder="" value={this.state.quarter_1} onChange={e => this.setState({ quarter_1: e.target.value })}></textarea>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>{"Plán na "+this.state.year+" / 02:"}</span>
                                <textarea className="" type="text" style={{flex: 1, border: 0, resize: "none"}} rows={5} name="obsah" placeholder="" value={this.state.quarter_2} onChange={e => this.setState({ quarter_2: e.target.value })}></textarea>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>{"Plán na "+this.state.year+" / 03:"}</span>
                                <textarea className="" type="text" style={{flex: 1, border: 0, resize: "none"}} rows={5} name="obsah" placeholder="" value={this.state.quarter_3} onChange={e => this.setState({ quarter_3: e.target.value })}></textarea>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>{"Plán na "+this.state.year+" / 04:"}</span>
                                <textarea className="" type="text" style={{flex: 1, border: 0, resize: "none"}} rows={5} name="obsah" placeholder="" value={this.state.quarter_4} onChange={e => this.setState({ quarter_4: e.target.value })}></textarea>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>{"Plán na "+this.state.year+":"}</span>
                                <textarea className="" type="text" style={{flex: 1, border: 0, resize: "none"}} rows={10} name="obsah" placeholder="" value={this.state.year_vision} onChange={e => this.setState({ year_vision: e.target.value })}></textarea>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>{"Rok:*"}</span>
                                <input className="" type="number" min={1000} max={9999} style={{flex: 1, border: 0, resize: "none"}} name="obsah" placeholder="" value={this.state.year} onChange={e => this.setState({ year: e.target.value })}/>
                            </div>

                            <div style={{height: 15}}></div>

                            <div className="horizontalStack">
                                <RoundButton title={"Uložit"} style={{color: "white", backgroundColor: "#ff6600"}} onClick={this.onSavePlan} whiteText={true} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default PlanEditor;
