import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import '../styles/tiles.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
//import draftToMarkdown from 'draftjs-to-markdown';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';
import ExpandButton from '../components/ExpandButton.js';
import AreaHeaderTile from '../tiles/AreaHeaderTile.js';

// assets
import icon_add_white from '../assets/icon_add_white.png';

class DocumentEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            editingExisting: false,
            editingNotAvailable: false,
            title: "",
            content: "",
            richContent: undefined,
        }
    }

    componentWillMount(){
        this.setState({ redirectTo: null });
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = () => {
        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){
            Axios.get("/api/document/get/"+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                this.setState({ title: response.data.result[0].title });
                this.setState({ content: response.data.result[0].content });

                const markdownString = response.data.result[0].content;
                const rawData = markdownToDraft(markdownString);
                const contentState = convertFromRaw(rawData);
                const newEditorState = EditorState.createWithContent(contentState);
                this.setState({ richContent: newEditorState });
                //alert(JSON.stringify(newEditorState));

                this.setState({ editingExisting: true });
                this.setState({ editingNotAvailable: true });
                //alert(JSON.stringify(<Moment format="dd M. YYYY">{response.data.result[0].date_time}</Moment>));
            });

        }

    }

    onSaveDocument = () => {
        var id_kra = localStorage.getItem("actualKRA") || 0;
        var title = this.state.title;
        //var content = this.state.content;

        const contentVariable = this.state.richContent.getCurrentContent();
        const rawObject = convertToRaw(contentVariable);
        const markdownString = draftToMarkdown(rawObject);
        var content = markdownString;

        if(title.length == 0){
            alert("Název dokumentu nesmí být prázdný.");
        }

        //alert("UPDATE: "+id_kra+", "+title+", "+content);

        var url = "/api/document/ins";

        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){ url = "/api/document/upd/"+id; }

        Axios.post(url, {
            id_kra: id_kra,
            title: title,
            content: content,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }

            if(this.state.editingExisting){
                this.setState({ editingNotAvailable: true });
            }
            else {
                navigate("/DocumentEditor?id="+response.data.result.insertId);
                window.location.reload(true);
            }
            // this starts unending cycle of page DocumentEditor reloading itself again and again
            //this.setState({ redirectTo: "/DocumentEditor?id="+response.data.result.insertId });
        });

        //this.state.richContent && draftToMarkdown(convertToRaw(this.state.richContent.getCurrentContent()))}

    }

    onEditorStateChange = (editorState) => {
        this.setState({ richContent: editorState });
    };

    deleteDocument = () => {
        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){
            Axios.get("/api/document/del/"+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                this.setState({ redirectTo: "/Area" });
            });
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 70px)", overflowY: "scroll"}}>

                <AreaHeaderTile />

                <div className="universalTile" style={{marginBottom: 20}}>
                    <div className="verticalStack">
                        <div className="verticalStack" style={{padding: 12, backgroundColor: "#F4F4F8"}}>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Název dokumentu:*</span>
                                <input className="profileTileInputText" type="text" style={{flex: 1, fontSize: 30}} id="songTitle" name="songTitle" value={this.state.title} onChange={(event) => this.setState({title: event.target.value})}/>
                            </div>

                            <div style={{height: 10}}></div>

                            <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Obsah dokumentu:</span>

                            {/*<div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Obsah dokumentu:</span>
                                <textarea className="" type="text" style={{flex: 1, border: 0, resize: "none"}} rows={10} name="obsah" placeholder="" value={this.state.content} onChange={e => this.setState({ content: e.target.value })}></textarea>
                            </div>*/}

                            <Editor
                                toolbarHidden={this.state.editingNotAvailable}
                                readOnly={this.state.editingNotAvailable}
                                editorState={this.state.richContent}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                wrapperStyle={{ minHeight: 100, border: "0px solid black", backgroundColor: "white"}}
                                editorStyle={{ paddingLeft: 10, paddingRight: 10 }}
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={{
                                    options: ['inline', 'blockType', 'list', 'link', 'remove', 'history'],
                                    blockType: {
                                      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
                                    },
                                    list: {
                                      options: ["unordered", "ordered"],
                                    },
                                }}
                            />

                            <div style={{height: 10}}></div>

                            <div className="horizontalStack">
                                {!this.state.editingNotAvailable ? <RoundButton title={"Uložit"} style={{color: "white", backgroundColor: "#ff6600"}} onClick={this.onSaveDocument} whiteText={true} /> : null }
                                {this.state.editingExisting && this.state.editingNotAvailable ? <RoundButton title={"Upravit"} style={{color: "white", backgroundColor: "#ff6600"}} onClick={() => this.setState({ editingNotAvailable: false })} whiteText={true} /> : null }
                                {this.state.editingExisting && !this.state.editingNotAvailable ? <RoundButton title={"Smazat"} style={{color: "white", backgroundColor: "gray", marginLeft: 10}} onClick={this.deleteDocument} whiteText={true} /> : null }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default DocumentEditor;
