import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import '../styles/tiles.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';

// assets
import icon_add_white from '../assets/icon_add_white.png';

class Area extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            kraObject: {id_kra: 1, title:"Title",description: "description",vision: "Vision"},
            arrayDocuments: ([{id_document: 1, title:"Document 1", content: ""},
                            {id_document: 2, title:"Document 2", content: ""}]),
            arrayOfTasks: ([{id_task: 1, title:"Loading...", status: "not_done", date_time: "2023-10-19T22:00:00.000Z"},
                            {id_task: 2, title:"Loading...", status: "not_done", date_time: "2023-10-19T22:00:00.000Z"}]),
            planObject: {id_plan: 1, quarter_1:"Loading...", quarter_2:"Loading...", quarter_3:"Loading...", quarter_4:"Loading...", year_vision:"Loading...", year: "Loading..."},
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = () => {
        //const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        const id = localStorage.getItem("actualKRA") || undefined;
        if(id != undefined && Number.isInteger(Number(id))){
            Axios.get("/api/kra/get/"+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                //alert(JSON.stringify(response.data.result[0]))
                this.setState({ kraObject: response.data.result[0] });
            });

            Axios.get("/api/document/get?kra="+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                //alert(JSON.stringify(response.data.result[0]))
                this.setState({ arrayDocuments: response.data.result });
            });

            Axios.get("/api/plan/get?kra="+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                //alert(JSON.stringify(response.data.result[0]))
                if(response.data.result.length >= 1){
                    this.setState({ planObject: response.data.result[0] });
                }
            });
            Axios.get("/api/task/get?kra="+id+"&status=not_done").then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                //alert(JSON.stringify(response.data.result));
                this.setState({ arrayOfTasks: response.data.result });
            });
        }
    }


    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 70px)", overflowY: "scroll"}}>

                <div className="universalTile">
                    <div className="verticalStack">
                        <div className="horizontalStack">
                            <span className="fontPoppinsBold25">{this.state.kraObject.title}</span>

                            <div className="flex"></div>

                            <RoundButton title={"Upravit"} style={{color: "white", backgroundColor: "#d4daf9"}} onClick={() => this.setState({ redirectTo: "/AreaEditor" })} whiteText={false} />
                        </div>

                        <span className="fontPoppinsItalic13">{this.state.kraObject.vision}</span>
                    </div>
                </div>

                <div className="universalTile">
                    <div className="horizontalStackCenter">
                            <span className="fontPoppinsSemiBold15 clickable" onClick={() => this.setState({ redirectTo: "/Tasks" })}>{"Úkoly ("}{this.state.arrayOfTasks.length}{")"}</span>

                            <div className="flex"></div>

                            <div className="circleButtonContainer circleButtonContainerOrange clickable" style={{marginTop: 0}} onClick={() => this.setState({ redirectTo: "/Task" })}>
                                <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                            </div>
                    </div>
                </div>

                <div className="universalTile">
                    <div className="verticalStack">
                        <div className="horizontalStack">
                            <span className="fontPoppinsSemiBold15">{"Dokumenty"}</span>

                            <div className="flex"></div>

                            <div className="circleButtonContainer circleButtonContainerOrange clickable" style={{marginTop: 5}} onClick={() => this.setState({ redirectTo: "/DocumentEditor" })}>
                                <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                            </div>
                        </div>

                        <div style={{height: 10}}></div>

                        {this.state.arrayDocuments.map(function (item) {
                            return (
                                <div>
                                    <div style={{width: "100%", height: 1, backgroundColor: "var(--light-gray)"}}></div>
                                    <div className="horizontalStackCenter clickable" style={{marginTop: 5, marginBottom: 5}} onClick={() => this.setState({ redirectTo: "/DocumentEditor?id="+item.id_document })}>
                                        <span className="fontPoppinsRegular13">{item.title}</span>
                                        <div className="flex"></div>
                                        <RoundButton title={"Otevřít"} style={{color: "white", backgroundColor: "#d4daf9"}} whiteText={false} />
                                    </div>
                                </div>
                            )
                        }.bind(this))}

                        <div style={{width: "100%", height: 1, backgroundColor: "var(--light-gray)"}}></div>

                        <div style={{height: 0}}></div>
                    </div>
                </div>

                <div className="universalTile" style={{marginBottom: 20}}>
                    <div className="verticalStack">
                        <div className="horizontalStack">
                            <span className="fontPoppinsSemiBold15">{"Plány"}</span>

                            <div className="flex"></div>

                            <RoundButton title={"Upravit"} style={{color: "white", backgroundColor: "#d4daf9"}} onClick={() => this.setState({ redirectTo: "/PlanEditor" })} whiteText={false} />
                        </div>

                        <span className="fontPoppinsRegular13">{"Plán na "+this.state.planObject.year+" / 01:"}</span>
                        <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "pre-wrap"}}>{this.state.planObject.quarter_1}</span>
                        <div style={{height: 10}}></div>

                        <span className="fontPoppinsRegular13">{"Plán na "+this.state.planObject.year+" / 02:"}</span>
                        <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "pre-wrap"}}>{this.state.planObject.quarter_2}</span>
                        <div style={{height: 10}}></div>

                        <span className="fontPoppinsRegular13">{"Plán na "+this.state.planObject.year+" / 03:"}</span>
                        <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "pre-wrap"}}>{this.state.planObject.quarter_3}</span>
                        <div style={{height: 10}}></div>

                        <span className="fontPoppinsRegular13">{"Plán na "+this.state.planObject.year+" / 04:"}</span>
                        <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "pre-wrap"}}>{this.state.planObject.quarter_4}</span>
                        <div style={{height: 10}}></div>

                        <span className="fontPoppinsSemiBold13">{"Plán na "+this.state.planObject.year+":"}</span>
                        <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "pre-wrap"}}>{this.state.planObject.year_vision}</span>
                        <div style={{height: 10}}></div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Area;
