import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/menus.css';
import { Link } from "react-router-dom";
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';

// import assets
import icon_account from '../assets/icon_account.png';
import icon_logout from '../assets/icon_logout.png';

class TopMenu extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            name: "User",
            surname: "Name",
            windowHeight: 1000,
        };
    }

    async componentDidMount(){
        this.loadData();
        window.addEventListener('resize', this.checkTopMenuResponsivity);

        this.checkTopMenuResponsivity();
        await new Promise(resolve => setTimeout(resolve, 50));
        this.checkTopMenuResponsivity();
    }

    loadData = async () => {
        Axios.get('/api/users/logged').then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }

            this.setState({ name: response.data.result[0].name  });
            this.setState({ surname: response.data.result[0].surname  });
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkTopMenuResponsivity);
    }

    checkTopMenuResponsivity = () => {
        this.setState({ windowHeight: window.innerWidth });
    }

    isSongsActive(actualSection){
        if(actualSection == "songs"){ return true; }
        else { return false; }
    }

    isPlaylistsActive(actualSection){
        if(actualSection == "playlists"){ return true; }
        else { return false; }
    }

    isTasksActive(actualSection){
        if(actualSection == "tasks"){ return true; }
        else { return false; }
    }

    logoutUser = () => {
        localStorage.setItem("saved_token", "");
        window.location.reload();
    }

    render(){
        return(
            <div className="verticalStack">

            {this.state.windowHeight >= 900 ?
                <div className="mainTopMenu horizontalStackCenter">
                        <div onClick={() => this.setActualSection("homepage")}>
                            <Link className="link" to={"./"} >
                                <span className="logoText clickable">{"Compass"}</span>
                            </Link>
                        </div>

                        <div className="flex"/>

                        <div style={{width: "2px", height: "100%", backgroundColor: "#f4f4f8"}}/>

                        <div className="topMenuUserButtonDropdownContainer">

                            <div className="topMenuUserButtonDropdownVisible">
                                <div className="horizontalStackCenter topMenuUserButton">
                                    <span className="fontPoppinsRegular13" style={{paddingRight: "12.5px", paddingLeft: "24.5px"}}>{this.state.name} {this.state.surname}</span>
                                </div>
                            </div>

                            <div className="topMenuUserButtonDropdownHidden">

                                <div onClick={() => this.setActualSection("account")}>
                                    <Link className="link" to={"./Account"} >
                                        <div className="flex horizontalStackCenter topMenuUserButtonDropdownRow">
                                            <span className="fontPoppinsRegular13DarkGray">{"Zobrazit profil"}</span>
                                            <div class="flex"></div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="topMenuUserButtonDropdownRow">
                                    <span className="fontPoppinsRegular13DarkGray" onClick={this.logoutUser}>{"Odhlásit se"}</span>
                                    <div class="flex"></div>
                                </div>

                            </div>
                        </div>
                </div>
            : null }

            {this.state.windowHeight < 900 ?
                <div className="mainTopMenu horizontalStackCenter">
                        <div onClick={() => this.setActualSection("homepage")}>
                            <Link className="link" to={"./"} >
                                <span className="logoText clickable">{"Compass"}</span>
                            </Link>
                        </div>

                        <div className="flex"/>



                        <div style={{width: "2px", height: "100%", backgroundColor: "#f4f4f8"}}/>

                        <div className="topMenuUserButtonDropdownContainer">

                            <div className="topMenuUserButtonDropdownVisible">
                                <div className="horizontalStackCenter topMenuUserButton clickable">
                                    <span className="fontPoppinsRegular13" style={{paddingRight: "12.5px", paddingLeft: "24.5px"}}>{this.state.name[0]}. {this.state.surname[0]}.</span>
                                </div>
                            </div>

                            <div className="topMenuUserButtonDropdownHidden">

                                <div onClick={() => this.setActualSection("account")}>
                                    <Link className="link" to={"./Account"} >
                                        <div className="flex horizontalStackCenter topMenuUserButtonDropdownRow">
                                            <img style={{height: "30px"}} src={icon_account}/>
                                            <div class="flex"></div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="topMenuUserButtonDropdownRow">
                                    <img style={{height: "30px"}} src={icon_logout} onClick={this.logoutUser}/>
                                    <div class="flex"></div>
                                </div>

                            </div>
                        </div>
                </div>
            : null }
            </div>
        );
	}
}

export default TopMenu;