import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import '../styles/tiles.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';

// assets
import icon_add_white from '../assets/icon_add_white.png';

class HomePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            loadDaysLimit: 30,
            date: "",
            arrayOfAreas: ([{id_kra: 1, title:"Loading..."},
                            {id_kra: 2, title:"Loading..."},
                            {id_kra: 3, title:"Loading..."},
                            {id_kra: 4, title:"Loading..."},
                            {id_kra: 5, title:"Loading..."}]),
            arrayOfTasks: ([{id_task: 1, title:"Loading...", status: "not_done", date_time: "2023-10-19T22:00:00.000Z"},
                            {id_task: 2, title:"Loading...", status: "not_done", date_time: "2023-10-19T22:00:00.000Z"}]),
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = () => {
        Axios.get("/api/kra/get").then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            this.setState({ arrayOfAreas: response.data.result });
        });

        var date = this.state.date;
        if(date == ""){
            //var today = new Date();
            //date = today.getFullYear()  + "-" + (today.getMonth()+1) + "-" + today.getDate();
            date = moment(new Date()).format('YYYY-MM-DD')
            this.setState({ date: date });
        }

        var dateStart = date;
        var dateEnd = this.getDatePlusDays(date, this.state.loadDaysLimit);

        Axios.get("/api/task/get?dateStart="+dateStart+"&dateEnd="+dateEnd+"&limit=5").then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            //alert(JSON.stringify(response.data.result));
            this.setState({ arrayOfTasks: response.data.result });
        });
    }

    getNumberOfTasksForTheDay = (date) => {
        var result = this.state.arrayOfTasks.filter(task => {
            return moment(new Date(task.date_time)).format('YYYY-MM-DD') === moment(new Date(date)).format('YYYY-MM-DD')
        })
        return result.length;
    }

    getDatePlusDays = (date, plusDays) => {
        var startDate = new Date(date);
        var day = 60 * 60 * 24 * 1000;
        var endDate = new Date(startDate.getTime() + (plusDays * day));

        //var correctFormat = endDate.getFullYear()  + "-" + (endDate.getMonth()+1) + "-" + endDate.getDate();
        var correctFormat = moment(new Date(endDate)).format('YYYY-MM-DD')
        return correctFormat;
    }

    changeTaskStatus = (task) => {
        Axios.post("/api/task/upd/"+task.id_task, {
            id_kra: task.id_kra,
            title: task.title,
            description: task.description,
            status: task.status == "not_done" ? "done" : "not_done",
            date_time: moment(new Date(task.date_time)).format('YYYY-MM-DD HH:mm:ss'),
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            this.loadData();
        });
    }

    showTasksForTheDay = (date) => {
        var result = this.state.arrayOfTasks.filter(task => {
            return moment(new Date(task.date_time)).format('YYYY-MM-DD') === moment(new Date(date)).format('YYYY-MM-DD')
        })
        return(
            <div>
                {result.map((item,index) => {
                    //alert(JSON.stringify(foundArea));

                    return (
                        <div key={index}>
                            <div className="horizontalStackCenter clickable" style={{marginTop: 5, marginBottom: 5}}>
                                <input type="checkbox" className="clickable" checked={item.status == "not_done" ? false : true} onChange={() => this.changeTaskStatus(item)} />
                                <div className="verticalStack" style={{marginLeft: 5}} onClick={() => this.setState({ redirectTo: "/Task?id="+item.id_task })}>
                                    <span className="fontPoppinsRegular13"><span className="fontPoppinsRegular13Gray">{this.getAreaName(item.id_kra)}</span>{" | "}{item.title}</span>
                                </div>
                                <div className="flex"></div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    getAreaName = (id) => {
        var foundArea = this.state.arrayOfAreas.filter(area => {
            return id === area.id_kra
        })
        return foundArea[0].title;
    }


    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 70px)", overflowY: "scroll"}}>

                <div className="universalTile">
                    <div className="verticalStack">
                        <div className="horizontalStack">
                            <span className="fontPoppinsSemiBold15">{"Kalendář úkolů"}</span>

                            <div className="flex"></div>

                            <RoundButton title={"Otevřít"} style={{color: "white", backgroundColor: "#d4daf9"}} onClick={() => this.setState({ redirectTo: "/Schedule" })} whiteText={false} />
                        </div>

                        {[...Array(this.state.loadDaysLimit)].map((x, i) =>
                            <div className="verticalStack" key={i}>
                                <span className="fontPoppinsSemiBold15" style={{display: this.getNumberOfTasksForTheDay(this.getDatePlusDays(this.state.date, i)) > 0 ? "" : "none"}}><Moment format="ddd, MMM DD">{this.getDatePlusDays(this.state.date, i)}</Moment></span>

                                {this.showTasksForTheDay(this.getDatePlusDays(this.state.date, i))}
                            </div>
                        )}

                        {this.state.arrayOfTasks.length == 0 ? <span className="fontPoppinsRegular13 clickable" style={{width: "75%"}} onClick={() => this.setState({ redirectTo: "/Schedule" })}>{"Pro následujících "}{this.state.loadDaysLimit}{" dní nemáte naplánované žádné úkoly."}</span>
                        : <span className="fontPoppinsRegular13 clickable" onClick={() => this.setState({ redirectTo: "/Schedule" })}>{"A více ..."}</span>}
                    </div>
                </div>

                <div className="universalTile" style={{marginBottom: 20}}>
                    <div className="verticalStack">
                        <div className="horizontalStack">
                            <span className="fontPoppinsSemiBold15">{"Oblasti"}</span>

                            <div className="flex"></div>

                            <div className="circleButtonContainer circleButtonContainerOrange clickable" style={{marginTop: 5}} onClick={() => this.setState({ redirectTo: "/AreaEditor?create=newArea" })}>
                                <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                            </div>
                        </div>

                        <div style={{height: 10}}></div>

                        {this.state.arrayOfAreas.map(function (item,index) {
                            return (
                                <div key={index}>
                                    <div style={{width: "100%", height: 1, backgroundColor: "var(--light-gray)"}}></div>
                                    <div className="horizontalStackCenter clickable" style={{marginTop: 5, marginBottom: 5}} onClick={() => {localStorage.setItem("actualKRA", item.id_kra);this.setState({ redirectTo: "/Area" });}}>
                                        <span className="fontPoppinsRegular13">{item.title}</span>
                                        <div className="flex"></div>
                                        <RoundButton title={"Otevřít"} style={{color: "white", backgroundColor: "#d4daf9"}} whiteText={false} />
                                    </div>
                                </div>
                            )
                        }.bind(this))}

                        <div style={{width: "100%", height: 1, backgroundColor: "var(--light-gray)"}}></div>

                        <div style={{height: 0}}></div>
                    </div>
                </div>

            </div>
        );
    }
}

export default HomePage;
