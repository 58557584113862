import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import '../styles/tiles.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';
import ExpandButton from '../components/ExpandButton.js';
import AreaHeaderTile from '../tiles/AreaHeaderTile.js';

// assets
import icon_add_white from '../assets/icon_add_white.png';

class Task extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            editingExisting: false,
            title: "",
            description: "",
            status: false,
            dateTime: "2018-06-12T19:30",
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = () => {
        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){
            Axios.get("/api/task/get/"+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }

                this.setState({ title: response.data.result[0].title });
                this.setState({ description: response.data.result[0].description });
                this.setState({ status: response.data.result[0].status == "not_done" ? false : true });
                this.setState({ dateTime: moment(response.data.result[0].date_time).format('YYYY-MM-DDTHH:mm') });
                this.setState({ editingExisting: true });
                //alert(JSON.stringify(<Moment format="dd M. YYYY">{response.data.result[0].date_time}</Moment>));
            });

        }
        else {
            var date = new Date();
            this.setState({ dateTime: moment(date).format('YYYY-MM-DDTHH:mm') });
        }

    }

    onSaveTask = () => {
        var id_kra = localStorage.getItem("actualKRA") || 0;
        var title = this.state.title;
        var description = this.state.description;
        var status = status ? "done" : "not_done";
        var date_time = moment(this.state.dateTime).format('YYYY-MM-DD HH:mm:ss');

        if(title.length == 0){
            alert("Název úkolu nesmí být prázdný.");
        }

        //alert("UPDATE: "+id_kra+", "+title+", "+description+", "+status+", "+date_time);

        var url = "/api/task/ins";

        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){ url = "/api/task/upd/"+id; }

        Axios.post(url, {
            id_kra: id_kra,
            title: title,
            description: description,
            status: status,
            date_time: date_time,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            //this.setState({ redirectTo: "/Tasks" });
            navigate(-1);
        });

    }

    deleteTask = () => {
        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){
            Axios.get("/api/task/del/"+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                //this.setState({ redirectTo: "/Tasks" });
                navigate(-1);
            });
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 70px)", overflowY: "scroll"}}>

                <AreaHeaderTile />

                <div className="universalTile">
                    <div className="verticalStack">
                        <span className="fontPoppinsSemiBold15">{this.state.editingExisting ? "Upravit úkol" : "Vytvořit nový úkol"}</span>
                        <div style={{height: 10}}></div>

                        <div className="verticalStack" style={{padding: 12, backgroundColor: "#F4F4F8"}}>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Název úkolu:*</span>
                                <input className="profileTileInputText" type="text" style={{flex: 1}} id="songTitle" name="songTitle" value={this.state.title} onChange={(event) => this.setState({title: event.target.value})}/>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Popis úkolu:</span>
                                <textarea className="" type="text" style={{flex: 1, border: 0, resize: "none"}} rows={10} name="obsah" placeholder="" value={this.state.description} onChange={e => this.setState({ description: e.target.value })}></textarea>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <div className="horizontalStack clickable" style={{marginTop: 3, marginBottom: 3}} onClick={() => this.setState({ status: !this.state.status })}>
                                    <label for={""} className="fontPoppinsRegular13 clickable" >
                                        <span className="fontPoppinsRegular13 clickable">{"Status:"}</span>
                                    </label>
                                    <input type="checkbox" className="clickable" checked={this.state.status} />
                                </div>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack flex">
                                <span className="fontPoppinsRegular13">Datum:*</span>
                                <input className="fontPoppinsRegular13" type="datetime-local" id="date" name="date" autocomplete="off" placeholder="" value={this.state.dateTime} onChange={e => {this.setState({ dateTime: e.target.value });}} style={{flex: 1, marginTop: 5, marginBottom: 5, border: "0px solid black"}}/>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="horizontalStack">
                                <RoundButton title={"Uložit"} style={{color: "white", backgroundColor: "#ff6600"}} onClick={this.onSaveTask} whiteText={true} />
                                {this.state.editingExisting ? <RoundButton title={"Smazat"} style={{color: "white", backgroundColor: "gray", marginLeft: 10}} onClick={this.deleteTask} whiteText={true} /> : null }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Task;
