import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import { Link, Navigate } from "react-router-dom";

// import components
import RoundButton from '../components/RoundButton.js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import logo2 from '../assets/logo2.png';

class HomePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
    }

    openLogin = () => {
        window.location.href = "/Login";
    }


    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return (
            <div className="verticalStack flex" style={{height: "100vh", backgroundColor: "#f4f4f8", overflowY: "scroll"}}>
                <div className="mainTopMenu horizontalStackCenter">

                    <img style={{height: "50px"}} src={logo2}/>

                    <div style={{width: 10}}></div>

                    <span className="logoText">{"Compass"}</span>

                    <div className="flex"/>

                    <RoundButton title={"Přihlásit se"} style={{marginRight: 20}} onClick={() => this.openLogin()} />
                </div>

                <div className="universalTile" style={{marginRight: 20, marginLeft: 20}}>
                    <div className="verticalStack">
                        <span className="fontPoppinsSemiBold15Blue">{"Co je Compass web"}</span>
                        <span className="fontPoppinsRegular13" style={{width: "75%"}}>{"Compass web je webový systém pro management úkolů, plánování a ukládání poznámek."}</span>
                    </div>
                    <div className="verticalStack" style={{marginTop: 20}}>
                        <span className="fontPoppinsSemiBold15Blue">{"Struktura Compass dat"}</span>
                        <span className="fontPoppinsRegular13" style={{width: "75%"}}>{"Můžete si vytvořit různé "}<span className="fontPoppinsSemiBold13">{"oblasti"}</span>{", které jsou pro vás důležité, pro každou tuto oblast si dále můžete vytvářet:"}</span>
                        <div style={{height: 10}}></div>
                        <div className="verticalStack" style={{marginLeft: 20, marginRight: 20}}>
                            <span className="fontPoppinsSemiBold15">{"• Úkoly"}</span>
                            <span className="fontPoppinsRegular13" style={{marginLeft: 10}}>{"Každý úkol může mít svůj název a podrobnější popis, dále datum a status HOTOVÝ/NEHOTOVÝ. Tyto úkoly je možné zobrazovat i v kalendáři."}</span>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsSemiBold15">{"• Dokumenty"}</span>
                            <span className="fontPoppinsRegular13" style={{marginLeft: 10}}>{"Do dokumentu si můžete ukládat své myšlenky, poznámky, apod. Text v dokumentech lze základně formátovat (nadpisy, odrážky, odkazy, tučnost, kruzíva, atd...)"}</span>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsSemiBold15">{"• Plány"}</span>
                            <span className="fontPoppinsRegular13" style={{marginLeft: 10}}>{"Pro každou oblast si můžete napsat plán na daný rok a rozdělit si jednotlivé podčásti plánu do jednotlivých kvartálů daného roku."}</span>
                        </div>
                    </div>
                </div>

                <div className="universalTile" style={{marginRight: 20, marginLeft: 20, marginBottom: 20}}>
                    <div className="verticalStack">
                        <span className="fontPoppinsSemiBold15Blue">{"Kontakt"}</span>
                        <span className="fontPoppinsRegular13" style={{width: "75%"}}>{"Jméno: Samuel Šenigl"}</span>
                        <span className="fontPoppinsRegular13" style={{width: "75%"}}>{"Tel.: 775 972 385"}</span>
                        <span className="fontPoppinsRegular13" style={{width: "75%"}}>{"IG: @samuelsenigl"}</span>
                    </div>
                </div>

            </div>
        );
    }
}

export default HomePage;
