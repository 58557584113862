import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import '../styles/tiles.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';
import ExpandButton from '../components/ExpandButton.js';
import AreaHeaderTile from '../tiles/AreaHeaderTile.js';

// assets
import icon_add_white from '../assets/icon_add_white.png';

class AreaEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            editingExisting: false,
            title: "",
            description: "",
            vision: "",
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = () => {
        const id = localStorage.getItem("actualKRA") || undefined;
        const { create } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(create == "newArea"){
            return;
        }
        if(id != undefined && Number.isInteger(Number(id))){
            Axios.get("/api/kra/get/"+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                //alert(JSON.stringify(response.data.result[0]))
                this.setState({ title: response.data.result[0].title });
                this.setState({ description: response.data.result[0].description });
                this.setState({ vision: response.data.result[0].vision });
                this.setState({ editingExisting: true });
            });
        }

    }

    onSaveKra = () => {
        var title = this.state.title;
        var description = this.state.description;
        var vision = this.state.vision;

        if(title.length == 0){
            alert("Název oblasti nesmí být prázdný.");
        }

        var url = "/api/kra/ins";

        const id = localStorage.getItem("actualKRA") || undefined;
        if(this.state.editingExisting && id != undefined && Number.isInteger(Number(id))){ url = "/api/kra/upd/"+id; }

        Axios.post(url, {
            title: title,
            description: description,
            vision: vision,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }

            if(this.state.editingExisting){
                this.setState({ redirectTo: "/Area" });
            }else {
                this.setState({ redirectTo: "/" });
            }
        });

    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 70px)", overflowY: "scroll"}}>

                <AreaHeaderTile />

                <div className="universalTile">
                    <div className="verticalStack">
                        <span className="fontPoppinsSemiBold15">{this.state.editingExisting ? "Upravit KRA" : "Vytvořit novou KRA"}</span>
                        <div style={{height: 10}}></div>

                        <div className="verticalStack" style={{padding: 12, backgroundColor: "#F4F4F8"}}>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Název KRA:*</span>
                                <input className="profileTileInputText" type="text" style={{flex: 1}} id="songTitle" name="songTitle" value={this.state.title} onChange={(event) => this.setState({title: event.target.value})}/>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Popis KRA:</span>
                                <textarea className="" type="text" style={{flex: 1, border: 0, resize: "none"}} rows={10} name="obsah" placeholder="" value={this.state.description} onChange={e => this.setState({ description: e.target.value })}></textarea>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Vize:</span>
                                <textarea className="" type="text" style={{flex: 1, border: 0, resize: "none"}} rows={10} name="obsah" placeholder="" value={this.state.vision} onChange={e => this.setState({ vision: e.target.value })}></textarea>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="horizontalStack">
                                <RoundButton title={"Uložit"} style={{color: "white", backgroundColor: "#ff6600"}} onClick={this.onSaveKra} whiteText={true} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default AreaEditor;
