import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import '../styles/tiles.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';

// assets
import icon_add_white from '../assets/icon_add_white.png';

class Document extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            kraObject: {id_kra: 1, title:"Title",description: "description",vision: "Vision"},
            arrayOfAreas: ([{id: 1, title:"Chvály | hlavní dokument"},
                            {id: 2, title:"Skládání chval"}]),
            objectDocument: {id_document: 1, title:"Document 1", content: ""},
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = () => {
        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){

            Axios.get("/api/document/get/"+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                //alert(JSON.stringify(response.data.result[0]))
                this.setState({ objectDocument: response.data.result[0] });
            });
        }

        const id_kra = localStorage.getItem("actualKRA") || 0;
        Axios.get("/api/kra/get/"+id_kra).then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            this.setState({ kraObject: response.data.result[0] });
        });

    }

    deleteDocument = () => {
        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){
            Axios.get("/api/document/del/"+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                this.setState({ redirectTo: "/Area" });
            });
        }
    }


    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 70px)", overflowY: "scroll"}}>

                <div className="universalTile">
                    <div className="verticalStack">
                        <div className="horizontalStack">
                            <span className="fontPoppinsBold25" onClick={() => this.setState({ redirectTo: "/Area" })}>{this.state.kraObject.title}</span>
                            <div className="flex"></div>
                            <RoundButton title={"Upravit"} style={{color: "white", backgroundColor: "#d4daf9"}} onClick={() => this.setState({ redirectTo: "/DocumentEditor?id="+this.state.objectDocument.id_document })} whiteText={false} />
                            <div style={{width: 10}}></div>
                            <RoundButton title={"Smazat"} style={{color: "white", backgroundColor: "#d4daf9"}} onClick={() => this.deleteDocument()} whiteText={false} />

                        </div>
                    </div>
                </div>

                <div className="universalTile">
                    <div className="verticalStack">
                        <span className="fontPoppinsBold25">{this.state.objectDocument.title}</span>

                        <div style={{height: 10}}></div>

                        <span className="fontPoppinsRegular13" style={{whiteSpace: "pre-wrap"}}>{this.state.objectDocument.content}</span>
                    </div>
                </div>

            </div>
        );
    }
}

export default Document;
