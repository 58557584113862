import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import '../styles/tiles.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';
import ExpandButton from '../components/ExpandButton.js';
import AreaHeaderTile from '../tiles/AreaHeaderTile.js';

// assets
import icon_add_white from '../assets/icon_add_white.png';

class Tasks extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            isDoneTasksOpen: false,
            kraObject: {id_kra: 1, title:"Title",description: "description",vision: "Vision"},
            arrayOfTasks: ([{id_task: 1, title:"Loading...", status: "not_done", date_time: "2023-10-19T22:00:00.000Z"},
                            {id_task: 2, title:"Loading...", status: "not_done", date_time: "2023-10-19T22:00:00.000Z"}]),
            arrayOfDoneTasks: ([{id_task: 1, title:"Loading...", status: "done", date_time: "2023-10-19T22:00:00.000Z"},
                            {id_task: 2, title:"Loading...", status: "done", date_time: "2023-10-19T22:00:00.000Z"}]),
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = () => {
        const id_kra = localStorage.getItem("actualKRA") || 0;
        Axios.get("/api/task/get?kra="+id_kra+"&status=not_done").then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            //alert(JSON.stringify(response.data.result));
            this.setState({ arrayOfTasks: response.data.result });
        });
        Axios.get("/api/task/get?kra="+id_kra+"&status=done").then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            //alert(JSON.stringify(response.data.result));
            this.setState({ arrayOfDoneTasks: response.data.result });
        });

        Axios.get("/api/kra/get/"+id_kra).then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            //alert(JSON.stringify(response.data.result[0]))
            this.setState({ kraObject: response.data.result[0] });
        });

    }

    openCloseDoneTasks = () => {
        this.setState({ isDoneTasksOpen: !this.state.isDoneTasksOpen });

    }

    changeTaskStatus = (task) => {
        Axios.post("/api/task/upd/"+task.id_task, {
            id_kra: task.id_kra,
            title: task.title,
            description: task.description,
            status: task.status == "not_done" ? "done" : "not_done",
            date_time: moment(task.date_time).format('YYYY-MM-DD HH:mm:ss'),
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            this.loadData();
        });
    }

    deleteTask = (task) => {
        Axios.get("/api/task/del/"+task.id_task).then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            this.loadData();
        });
    }


    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 70px)", overflowY: "scroll"}}>

                <AreaHeaderTile title={this.state.kraObject.title} />

                <div className="universalTile">
                    <div className="verticalStack">
                        <div className="horizontalStack">
                            <div className="verticalStack">
                                <span className="fontPoppinsSemiBold15">{"Úkoly"}</span>
                                <span className="fontPoppinsRegular13Gray">{"Seznam nedokončených úkolů."}</span>
                            </div>

                            <div className="flex"></div>

                            <div className="circleButtonContainer circleButtonContainerOrange clickable" style={{marginTop: 5}} onClick={() => this.setState({ redirectTo: "/Task" })}>
                                <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                            </div>
                        </div>

                        <div style={{height: 10}}></div>

                        {this.state.arrayOfTasks.map(function (item) {
                            return (
                                <div>
                                    <div style={{width: "100%", height: 1, backgroundColor: "var(--light-gray)"}}></div>
                                    <div className="horizontalStackCenter" style={{marginTop: 5, marginBottom: 5}}>
                                        <input type="checkbox" className="clickable" checked={item.status == "not_done" ? false : true} onClick={() => this.changeTaskStatus(item)} />
                                        <div className="verticalStack" style={{marginLeft: 5}}>
                                            <span className="fontPoppinsRegular13">{item.title}</span>
                                            <span className="fontPoppinsRegular13Gray"><Moment format="D. M. YYYY">{item.date_time}</Moment></span>
                                        </div>
                                        <div className="flex"></div>
                                        <RoundButton title={"Upravit"} style={{color: "white", backgroundColor: "#d4daf9"}} onClick={() => this.setState({ redirectTo: "/Task?id="+item.id_task })} whiteText={false} />
                                    </div>
                                </div>
                            )
                        }.bind(this))}

                        <div style={{width: "100%", height: 1, backgroundColor: "var(--light-gray)"}}></div>

                        <div style={{height: 0}}></div>
                    </div>
                </div>

                <div className="universalTile" style={{marginBottom: 20}}>
                    <div className="verticalStack">
                        <div className="horizontalStackCenter">
                            <div className="verticalStack">
                                <span className="fontPoppinsSemiBold15">{"Dokončené úkoly"}</span>
                                <span className="fontPoppinsRegular13Gray">{"Toto je archiv dokončených úkolů."}</span>
                            </div>


                            <div className="flex"></div>

                            <ExpandButton isOpen={this.state.isDoneTasksOpen} style={{marginTop: 5}} onClick={this.openCloseDoneTasks} />
                        </div>

                        {this.state.isDoneTasksOpen ?
                            <div>
                                <div style={{height: 10}}></div>

                                {this.state.arrayOfDoneTasks.map(function (item) {
                                    return (
                                        <div>
                                            <div style={{width: "100%", height: 1, backgroundColor: "var(--light-gray)"}}></div>
                                            <div className="horizontalStackCenter" style={{marginTop: 5, marginBottom: 5}}>
                                                <input type="checkbox" className="clickable" checked={item.status == "not_done" ? false : true} onClick={() => this.changeTaskStatus(item)} />
                                                <div className="verticalStack" style={{marginLeft: 5}}>
                                                    <span className="fontPoppinsRegular13">{item.title}</span>
                                                    <span className="fontPoppinsRegular13Gray"><Moment format="D. M. YYYY">{item.date_time}</Moment></span>
                                                </div>
                                                <div className="flex"></div>
                                                <RoundButton title={"Smazat"} style={{color: "white", backgroundColor: "#d4daf9"}} onClick={() => this.deleteTask(item)} whiteText={false} />
                                            </div>
                                        </div>
                                    )
                                }.bind(this))}

                                <div style={{width: "100%", height: 1, backgroundColor: "var(--light-gray)"}}></div>
                            </div>
                        : null}
                    </div>
                </div>

            </div>
        );
    }
}

export default Tasks;
